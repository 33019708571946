import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import Chart from 'react-apexcharts';

function GeneralMonitoring() {
  // State'i kullanarak options ve series'i tanımlıyoruz
  const [options, setOptions] = useState({
    chart: {
      id: 'apexchart-example'
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
    }
  });

  const [series, setSeries] = useState([{
    name: 'series-1',
    data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
  }]);

  return (
    <Grid container spacing={2} >
      <Grid item xs={6} md={6}>
        <Card container spacing={2}>
          <CardHeader titleTypographyProps={{ variant: 'subtitle1' }} title="Ürün Grubu Bazında Açık Değişimler Grafiği" />
          <CardContent >
            <Chart options={options} series={series} type="bar" width={'100%'} height={320} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>

  );
}

export default GeneralMonitoring;
