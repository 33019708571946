import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Typography
} from '@mui/material';
import ProductGroupStatusChangeChartView from 'src/components/ProductGroupStatusChangeChart.js';
import UserTypeBasedSubStatusChart from 'src/components/UserTypeBasedSubStatusChart';

function GeneralMonitoring() {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ProductGroupStatusChangeChartView />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <UserTypeBasedSubStatusChart />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default GeneralMonitoring;
