import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Chart from 'react-apexcharts';
import { Card, CardContent, Grid, Skeleton } from '@mui/material';
import {
  getSubStatus,
  getUserTypeBasedSubStatusCounts
} from 'src/store/slices/generalFollowSlice';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  firstCell: {
    borderLeft: 'none'
  }
}));

function UserTypeBasedSubStatusChart() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [mainData, setMainData] = React.useState([]);
  const [allSubStatus, setAllSubStatus] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const allCustomerTypes = ['Bayi', 'Müşteri', 'Tüzel Kişi-Şirket'];

  useEffect(() => {
    GetUserTypeBasedSubStatusCounts();
    GetAllSubStatus();
  }, []);

  const GetUserTypeBasedSubStatusCounts = () => {
    setLoading(true);
    dispatch(getUserTypeBasedSubStatusCounts())
      .then((response) => {
        const data = response.payload || [];
        setMainData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const GetAllSubStatus = () => {
    dispatch(getSubStatus()).then((response) => {
      const data = response.payload || [];
      setAllSubStatus(data);
    });
  };

  // Transform data into matrix format
  const matrixData = allCustomerTypes.map((musteriTipi) => {
    return allSubStatus.map((araDurum) => {
      const entry = mainData.find(
        (item) =>
          item.MUSTERI_TIPI_ACIKLAMA === musteriTipi &&
          item.ARA_DURUM_ACIKLAMA === araDurum.ACIKLAMA
      );
      return entry ? entry.ADET : 0;
    });
  });

  const series = allCustomerTypes.map((musteriTipi, index) => ({
    name: musteriTipi,
    data: matrixData[index]
  }));

  const options = {
    chart: {
      type: 'heatmap'
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            { from: 0, to: 0, color: '#B3E7F9', name: 'Yok' },
            { from: 1, to: 50, color: '#A0D2DB', name: 'Çok Düşük' },
            { from: 51, to: 500, color: '#F3B0C3', name: 'Düşük' },
            { from: 501, to: 5000, color: '#FAD775', name: 'Orta' },
            { from: 5001, to: 20000, color: '#FFD884', name: 'Yüksek' },
            { from: 20001, to: 100000, color: '#FFA07A', name: 'Çok Yüksek' }
          ]
        }
      }
    },
    stroke: {
      width: 1
    },
    dataLabels: {
      enabled: true
    },
    xaxis: {
      categories: allSubStatus.map((status) => status.ACIKLAMA),
      labels: {
        rotate: -45
      }
    },
    title: {
      text: 'Müşteri Tipi ve Ara Durum Adetleri'
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card>
          <CardContent>
            <Grid item xs={12} style={{ marginBottom: '1.5rem' }}>
              {loading ? (
                <>
                  <Grid item xs={12} md={12} lg={12} style={{ width: '100%' }}>
                    <Skeleton variant="rectangular" width="100%" height={500} />
                  </Grid>
                </>
              ) : (
                <Chart
                  options={options}
                  series={series}
                  type="heatmap"
                  height={500}
                />
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default UserTypeBasedSubStatusChart;
